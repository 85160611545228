.order-form {
  max-width: 300px;
  width: 100%;

  .form_block {
    margin: 1em 0 0;
    position: relative;
    
    & > .form_title {
      position: static;
    }
  }

  .picker-input {
    cursor: pointer;
  }

  .form_title {
    position: static;
  }

  .input-textarea,
  .input-text {
    width: 100%;
  }

  .input-textarea {
    height: 7em;
  }

  .required-message {
    font-size: 11px;
    margin: -1em 0 1em;
  }
}


.user-orders {
  margin: 0 0 1em;
}


.user-orders-deliver-table {
  border-bottom: 1px solid $borderColor;
  margin: 0 0 1em;
  text-align: left;
  table-layout: fixed;
  width: 100%;


  & > tbody {
    & > tr {
      border-bottom: 1px solid $borderColor;
    }
  }
}


.user-order {

  & > .total-table,
  & > .summ-table {
    table-layout: fixed;
    width: 275px;

    td {
      &:first-child {
        width: 200px;
      }

      &:last-child {
        width: 75px;
      }
    }

    .cart_prices {
      text-align: right;
    }
  }

  & > .total-table {
    margin: 0 0 1em;
  }
}


.order-table {
  margin: 1em 0;
  text-align: center;
  table-layout: fixed;
  width: 100%;

  td {
    padding: 6px 0;
  }

  & > thead {
    & > tr {
      border-bottom: 1px solid $borderColor;
    }
  }

  & > tbody {
    & > tr {
      border-bottom: 1px solid $borderColor;
    }
  }
}



.users-orders-table {
  margin: 1em 0;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;

  .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative;
  }


  .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%;
  }

  .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%;
  }

  & > thead {
    & > tr {
      border-bottom: 1px solid $borderColor;

      & > th {
        padding: 6px 0;

        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 33%;
        }
        &:nth-child(3) {
          width: 13%;
        }
        &:nth-child(4) {
          width: 16%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 13%;
        }

      }
    }
  }

  & > tbody {
    & > tr {
      border-bottom: 1px solid $borderColor;

      & > td {
        padding: 8px 0;
      }
    }
  }

}


