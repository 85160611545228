.module {
	@include module(false);
	.filter-wrapper,
	.vote-block{
		padding: 1em;
	}
	.news-block{
		padding: 1em 1em 0;
	}
	&.announcement{
		margin: 0;
		background: $white;
		padding: 1em 1em 0;
		border-radius: 0;
	}
}
/* Оформление модулей в контент области */
.module-inner {
	clear: both;
	margin: 1em 0 2em;
	& > .module-header {
		background: none;
		padding: .3em .5em;
		margin: 0 0 .5em;
		text-align: center;

		& > span,
		& > h2 {
			font-size: 20px;
			font-weight: 700;
			color: #196aad;
			text-transform: uppercase;
			padding: 0;
		}
	}
	&.services,
	&.new,
	&.hit,
	&.spec,
	&.prem_reviews,
	&.partners{
		// padding: 1em 0 0;
		// border-top: 1px solid #ececec;
	}
	&.prem_reviews,
	&.partners{
		& > .module-header {
			& > span,
			& > h2 {
				// text-align: left;
			}
		}
		.all_reviews{
			text-align: right;
		}
	}
	&.news-block{
		.news-all,
		.news-archive{
			text-align: right;
			a{
				padding: 0 1em;
			}
		}
	}
	&.spec_new_hit-block{
		.category-list{
			&__item{
				padding: 0;
				max-width: none;
				width: calc(33.333% - 1.34em);
				border: none;
				&:nth-child(3n + 3) {
					margin: 0 0 2em;
				}
				> a{
					padding: 1em;
					color: $textColor;
					&:hover{
						color: $linkColor--hover;
					}
				}
				&-image{
					max-height: 100px;
					width: auto;
				}
			}
		}
	}
}
.module-wrapper {
	position: relative;
}
/* Бренды */
.module.brands {

	.brands-list {
		display: block;
		list-style: none;
		padding: 1em;
	}

	.brands-list__item {
		font-size: 1.14286em;
		line-height: 1.9;

		& > span {
			color: $linkColor;
		}
	}

	.brands-list__link {
		color: $textColor;
		text-decoration: none;
		transition: color 0.3s ease;

		&:hover {
			color: $linkColor;
		}
	}
}
.module.brands {
	@include media(md) {
		float: left;
		padding: 0 0.5em 0 1em;
		width: 50%;
	}
	@include media(sm) {
		float: none;
		padding: 0;
		width: 100%;
	}
}
.mobile,
.tablet {
	section{
		.module-inner {
			padding: 1em 0;
		}
	}
	.module,
	.module-inner {
		// background: none;
		margin: 1em 0 0;
		& > .module-header {
			background: $moduleMobileHeaderBg;
			border-radius: $borderRadius;

			&.toggle{
				position: relative;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $white;
					left: inherit;
					right: 1em;
					margin: -10px 0 0;
				}
				&.active{
					&:before{
						content: '\f077';
					}
				}
			}
			& > span,
			& > h2 {
				color: $moduleMobileHeaderColor;
				& > a {
					text-decoration: none;
					color: $moduleMobileHeaderColor;
				}
			}
			@include media(md) {
				padding: 0.5em 1em;
				& > span,
				& > h2 {
					font-size: 16px;
				}
			}
		}
		&.prem_reviews,
		&.partners{
			& > .module-header {
				& > span,
				& > h2 {
					text-align: center;
				}
			}
			.all_reviews{
				text-align: center;
			}
		}
		.module-wrapper {
			padding: 0;
			margin: 0 0 1em;
		}
		.module-header{
			margin: 0 0 1em;
		}
	}
	.main-left-menu{
		margin: 0 0 1em;
		.module-header{
			margin: 0;
		}
		.module-wrapper {
			@include media(md) {
				display: none;
			}
		}
	}
}

.prem_reviews{
	.item{
		box-shadow: 0 0 0 1px #f2f2f2;
		background: $white;
		margin: 1em;
		padding: 1em;
		min-height: 267px;
		transition: box-shadow .3s ease;
		&:hover{
			box-shadow: 0 0 10px rgba(0,0,0,.1);
		}
		.top{
			display: flex;
			align-items: center;
			b{
				padding: 0 1em;
			}
		}
		.bottom{
			.bottom_text{
				padding: 1em 0;
				min-height: 100px;
			}
			.bottom_date{
				font-weight: 700;
				margin: 0 0 0.5em;
			}
		}
	}
	.all_reviews{
		text-align: center;
		padding: 0 1em;
	}
}

.partners{
	.item{
		img{
			display: block;
			margin: 0 auto;
		}
	}
}

.reviews_page{
	padding: 0 1em;
	.item{
		padding: 1em 0;
		border-bottom: 1px solid #ececec;
		&:nth-child(2n){
			background: $white;
		}
	}
}

.order-block {
	background: #fbfbfb;
	border: 1px solid #f2f2f2;
	color: #555;
	clear: both;
	font-size: 1em;
	margin: 30px 0 40px;
	padding: 2rem 0;
	width: 100%;
}
.order-block td {
	padding: 42px 0 37px;
	vertical-align: top;
	float: none;
	display: table-cell;
}
.order-block{
	.text {
		padding: 0 0 0 131px;
		min-height: 46px;
		line-height: 24px;
		color: #555;
		text-align: left;
		position: relative;
		@include media(sm) {
			padding: 0;
			text-align: center;
		}
		.svg {
			display: block;
			width: 48px;
			height: 58px;
			position: absolute;
			top: 39px;
			top: -2px;
			left: 52px;
			@include media(sm) {
				display: none;
			}
		}
	}
	.btns{
		text-align: center;
		margin: 1em 0 0;
	}
}

.btn.btn-default {
	background-color: #ff6d00;
	border-color: #ff6d00;
	color: #ffffff;
}

.btn, .btn.btn-lg {
	font-size: 1em;
}

.reviews_page .item {
	padding: 1rem 0;
}

.padded-bottom {
	padding-bottom: 1rem;
}

.uploader {
	border: 1px solid #e8e8e8;
	padding: 9px;
	border-radius: 2px;
	height: 52px;
	position: relative;
	margin-bottom: -1px;
}

.uploader input[type=file] {
	z-index: 10;
	opacity: 0;
	border: none;
	background: 0 0;
	position: absolute;
	top: 0;
	right: 0;
	float: right;
	cursor: default;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.uploader .filename {
	overflow: hidden;
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	font-size: 13px;
	color: #9aa6ae;
	position: relative;
	top: -5px;
	padding: 9px 9px 9px 145px;
	text-overflow: ellipsis;
}

.uploader .action {
	position: absolute;
	top: 9px;
	left: 9px;
	background: #fff;
	border-radius: 3px;
	color: #fff;
	padding: 7px 12px;
	font-size: 12px;
	line-height: 15px;
	margin-right: 10px;
	color: #ff6d00;
	border: 1px solid #ffd3b3;
}
.uploader:hover .action {
	background-color: #ff7f1f;
	border-color: #ff7f1f;
	color: #222;
}
.fadeFon {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0,.5);
	z-index: 999;
}
.float_window {
	max-width: 410px;
	width: 100%;
	position: absolute;
	top: 10%;
	left: 50%;
	margin: 0 0 0 -205px;
	background: #fff;
	padding: 2em 0 1em;
	@include media(sm) {
		width: calc(320px - 2em);
		margin: 0 0 0 calc(160px - 320px + 1em);
	}
	#fz152{
		+ label{
			cursor: pointer;
			font-size: 11px;
			display: inline-block;
			line-height: 1.333;
			padding: 0 0 18px 24px;
			margin: 0 0 -18px;
		}
	}
	.g-recaptcha{
		margin: 0 0 1em;
		@include media(sm) {
			margin: 0 0 0 -20px;
		}
	}
}
.float_window .form-item input[type="text"],
.float_window .form-item input[type="email"],
.float_window .form-item textarea {
	width: 100%;
	padding: .5rem;
	resize: none;
}

.float_window .form-item textarea {
	height: 125px;
}

.close_this_window {
	position: absolute;
	top: .5rem;
	right: 1.5rem;
	font-family: arial;
	cursor: pointer;
}

.add_file span{
	border-bottom: 1px dotted;
}
.add_file:hover,
.add_file:hover::before,
.close_this_window:hover {
	color: #fd6a1a;
}
.reds {
	color: #ff0000;
}

.add_file {
	color: #333;
	font-size: 12px;
	cursor: pointer;
	padding: 4px 10px 0;
	display: inline-block;

}
.add_file:before {
	content: "+";
	text-align: center;
	display: inline-block;
	width: 7px;
	height: 7px;
	margin: 0 5px 1px 0;
}
.hidden {
	display: none;
}

.tisers{
	> .container{
		padding: 2em 0 1em;
		display: flex;
		justify-content: space-between;
		background: $white;
		@include media(lg) {
			justify-content: center;
			flex-wrap: wrap;
		}
		&:before,
		&:after{
			display: none;
		}
	}
	.item{
		padding: 0 1em;
		flex: 1 1 100%;
		@include media(lg) {
			padding: 0 1em 1em;
			flex: 1 1 33.333%;
		}
		@include media(sm) {
			flex: 1 1 50%;
		}
		a{
			display: flex;
			align-items: center;
			text-decoration: none;
			@include media(slg) {
				align-items: center;
				flex-direction: column;
			}
		}
		&_img{
			display: flex;
			align-items: center;
			width: 58px;
			height: 50px;
			margin: 0 20px 0 0;
			@include media(slg) {
				margin: 0;
			}
			img{
				max-width: 58px;
				max-height: 50px;
			}
		}
		&_title{
			font-size: 12px;
			color: $textColor;
			@include media(slg) {
				text-align: center;
			}
		}
	}
}