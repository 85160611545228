#landing-list{
  .module-header{
    max-width: calc(#{$containerWidth} - 2em);
    margin: 0 auto 1em;
  }
}

.landing-list{
  &__item{
    &:not(:last-child){
      border-bottom: 1px solid #ececec;
    }
    padding: 1em 0;
    &-title{
      padding: 0 0 1em;
      span{
        display: block;
        font-size: 20px;
        font-weight: 700;
      }
    }
    &-image{
      background: $white;
      border: 1px solid $borderColor;
      overflow: hidden;
      img{
        @extend .img-responsive;
        max-height: 300px;
      }
    }
    &-link{
      text-align: right;
    }
  }
}