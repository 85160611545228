.nav-left {
	/*padding: 0 1em;*/
}

.nav-left {
	position: relative;

	.menu_level_1 {
		display: block;

		& > li {
			position: relative;
			list-style: none;
			transition: all 0.3s ease;
			-webkit-tap-highlight-color: $webkitHighlightTapColor;
			border-bottom: 1px dotted #ccc;

			.arrow {
				position: absolute;
				right: 8px;
				top: 0;
				width: 18px;
				height: 27px;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $textColor;
					line-height: 27px;
					transform: rotate(180deg);
					transition: transform 0.3s ease;
				}
				&.rotate {
					&:before {
						transform: rotate(0deg);
						transition: transform 0.3s ease;
					}
				}
			}
			&:first-child {
				border-top: none;
			}

			&:last-child {
				border-bottom: none;
			}

			& > a {
				display: block;
				color: $textColor;
				padding: 5px 1em;
				position: relative;
				text-decoration: none;
				transition: color 0.3s ease;

				&:after {

					@include fontawesome('\f054') {
						color: #333;
						font-size: 12px;
						margin-top: -7px;
						top: 50%;
						left: auto;
						right: 1em;
						transition: 0.1s ease 0.07s;
					}
				}
			}

			&.subcats_exists {

				&:not(.selected):hover {

					& > a {

						&:after {
							right: 1em;
							transform-origin: left bottom;
							transform: rotate(90deg);
						}
					}
				}
			}

			&.selected {

				cursor: default;
				> .arrow{
					&:before{
						color: $white;
					}
				}
				& > a {
					background: $linkColor--hover;
					color: #fff;
					text-decoration: none;
				}

				&.subcats_exists {
					& > a {
						&:after {
							color: #fff;
							transform: rotate(90deg);
						}
					}
				}
				.menu_level_2{
					display: block;
				}
			}
		}
	}

	.menu_level_2 {
		background: $white;
		display: none;
		padding: 0;

		& > li {
			position: relative;
			list-style: none;
			.arrow{
				top: -2px;
			}
			& > a {
				color: $textColor;
				display: block;
				font-size: 13px;
				padding: 5px 1em;
				text-decoration: none;
			}

			&:hover {
				& > a {
					color: $linkColor--hover;
					text-decoration: underline;
				}
			}

			&.selected {
				.arrow{
					&.rotate{
						&:before{
							// color: $linkColor--hover;
						}
					}
				}
				& > a {
					color: $linkColor--hover;
					text-decoration: none;
				}
			}
		}
	}

	.menu_level_3,
	.menu_level_4 {
		display: none;
		padding: 0.5em 0 0.75em 2em;

		& > li {
			position: relative;
			list-style: disc;
			padding: 0.2em 0;
			.arrow {
				top: -2px;
			}
			& > a {
				color: $textColor;
				display: block;
				text-decoration: none;
			}

			&.selected {
				color: $linkColor;
				& > a {
					color: $linkColor;
				}
				&:hover {
					color: $linkColor;
					& > a {
						color: $linkColor;
					}
				}
			}

			&:hover {
				color: $linkColor--hover;
				transition: color 0.3s ease;
				& > a {
					color: $linkColor--hover;
				}
			}
		}
	}
}


/* ховеры только на десктопах */
.desktop {
	.nav-left {
		.menu_level_1 {

			& > li {

				&:not(.selected) {
					&:hover {
						background: $linkColor;
						> .arrow{
							&:before{
								color: $white;
							}
						}
						& > a {
							color: $white;

							&:after {
								right: 0.8em;
							}
						}
					}
				}
				&.selected{
					&:hover {
						> .arrow{
							&:before{
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}