.index{
	&.desktop{
		&.video_on{
			nav{
				.menu_level_1{
					padding: 0;
				}
				.search{
					padding: 0 0 0 1em;
				}
			}
		}
	}
}

nav {
	@include navtop();
	width: 100%;
	position: relative;
	z-index: 3;

	.menu_level_1 {
		display: table;
		// padding: 0 0 0 1em;
		list-style: none;
		width: 100%;
		@include media(md) {
			display: block;
			text-align: center;
		}

		& > li {
			display: table-cell;
			position: relative;
			vertical-align: top;
			text-align: center;
			@include media(md) {
				display: inline-block;
			}
			.arrow {
				position: absolute;
				right: 0;
				top: 6px;
				width: 18px;
				height: 27px;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $white;
					line-height: 27px;
					transform: rotate(180deg);
					transition: transform 0.3s ease;
				}
			}
			& > a {
				position: relative;
				color: $white;
				display: block;
				padding: 0.8em 1em;
				text-decoration: none;
				text-align: center;
				text-transform: uppercase;
				> img{
					vertical-align: middle;
					margin: 0 5px 0 0;
				}
				@include media(lg) {
					padding: 0;
				}
				@include media(md) {
					padding: 0.5em;
				}
				.mob-icon{
					font-size: 18px;
					line-height: 30px;
					left: 0;
					@include media(lg) {
						display: none;
					}
				}
			}

			&.selected {
				cursor: default;
			 background: linear-gradient(to top, $buttonBgColor1, $buttonBgColor2);
				& > a {
					color: $white;
					.mob-icon{
						color: $white !important;
					}
				}
				>	.arrow{
					&:before{
						color: $white;
					}
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
		}


		&.deep-3,
		&.deep-2 {
			& > li {
				&.selected {
					& > a {

						&:hover {
						}
					}
				}
			}
		}


		&.deep-3 {
			.menu_level_2 {
				& > li {
					&.selected {
						& > a {

							&:hover {
							}
						}
					}
				}
			}
		}

	}

	/* Всплывающее меню */
	.menu_level_2 {
		background: #fff;
		box-shadow: none;
		display: table;
		list-style: none;
		// margin: -1px 0 0;
		// min-width: 150px;
		min-width: 100%;
		padding: 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 999;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		border-radius: 0 0 $borderRadius $borderRadius;

		& > li {
			position: relative;
			display: table-cell;
			padding: 0.5em 1.5em 0.5em 1em;
			.arrow{
				top: 3px;
				right: 0;
				&:before{
					transform: rotate(-90deg);
					color: $textColor;
				}
			}
			.menu-item__photo {
				float: left;
				width: 50%;

				& > img {
					display: block;
					height: auto;
					max-width: 100px;
					width: 100%;
				}
			}

			.menu-item__description {
				float: right;
				width: 50%;

				& > span {
					font-size: 12px;
				}
			}

			.menu-item__header {

				& > span {
					color: $textColor;
					font-size: 12px;
					vertical-align: top;
				}
			}

			& > a {
				color: $textColor;
				display: block;
				white-space: nowrap;
			}
			ul {
				position: absolute;
				top: 0;
				left: 100%;
				display: block;
				list-style: none;
				padding: 0 0 0 1.5em;
				background: $white;
				opacity: 0;
				visibility: hidden;
				transform: translate3d(-1em, 0, 0);
				box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);;

				& > li {
					.arrow {
						top: 0;
					}
					& > a {
						color: $textColor;
						display: block;
						white-space: nowrap;
						padding: .5em 2.5em .5em 0;
						&:hover{
							color: $linkColor--hover;
						}
					}

					&.selected {
						&:before{
							color: $linkColor--hover;
						}
						& > a {
							color: $linkColor--hover;
						}
					}
					ul{
						display: none;
					}
				}
			}
		}
	}
}

.desktop {

	nav {
		.menu_level_1 {
			position: relative;
			& > li {
				/* Показываем всплывашку на ховер */
				&.subcat {
					&:hover {
						> .arrow{
							&:before{
								color: $white;
								transform: translate3d(0,0,0);
								transition: transform 0.3s ease;
							}
						}
						>	ul {
							opacity: 1;
							transition: opacity 0.3s ease, transform 0.3s ease-out;
							visibility: visible;
							transform: translate3d(0, 0, 0);
							box-shadow: $module_top__box-shadow;
							& > li {
								&.subcat {
									&:hover {
										> .arrow{
											&:before{
												color: $linkColor--hover;
												transform: translate3d(0,0,0);
												transition: transform 0.3s ease;
											}
										}
										> ul{
											display: block;
											opacity: 1;
											visibility: visible;
											transition: left 0.3s ease, opacity 0.3s ease, transform 0.3s ease-out;
											transform: translate3d(0, 0, 0);
											border: 1px dotted #ccc;
											> li {
												&.subcat {
													&:hover {
														> .arrow{
															&:before{
																color: $linkColor--hover;
																transform: translate3d(0,0,0);
																transition: transform 0.3s ease;
															}
														}
														> ul{
															display: block;
															opacity: 1;
															visibility: visible;
															transition: left 0.3s ease, opacity 0.3s ease, transform 0.3s ease-out;
															transform: translate3d(0, 0, 0);
															border: 1px solid #ccc;
															> li {
																&.subcat {
																	&:hover {
																		> .arrow{
																			&:before{
																				color: $linkColor--hover;
																				transform: translate3d(0,0,0);
																				transition: transform 0.3s ease;
																			}
																		}
																		> ul{
																			display: block;
																			opacity: 1;
																			visibility: visible;
																			transition: left 0.3s ease, opacity 0.3s ease, transform 0.3s ease-out;
																			transform: translate3d(0, 0, 0);
																			border: 1px solid #ccc;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				&:not(.selected) {
					&:hover {
						background: linear-gradient(to bottom,#1187d4,#4071ad);
						transition: background 0.3s ease;
						& > a {
							color: $white;
							.mob-icon{
								color: $white !important;
							}
						}
					}
				}
			}
		}


		.menu_level_2 {
			display: table-row;
			left: 0;
			transform-origin: center;
			transform: translate3d(0, 1em, 0);

			& > li {
				float: left;
				width: 100%;

				&.selected {
					& > a {
						color: $linkColor--hover;
					}
					.arrow{
						&:before{
							color: $linkColor--hover;
						}
					}
				}

				&:not(.selected) {
					& > a {
						&:hover {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.menu_level_3,
		.menu_level_4 {
			& > li {
				position: relative;
				font-size: 13px;
				@include fontawesome('\f105') {
					font-size: 14px;
					color: $textColor;
					left: 0;
				}
				&.subcat {
					&:hover {
						> .arrow{
							&:before{
								color: $linkColor--hover;
								transform: translate3d(0,0,0);
								transition: transform 0.3s ease;
							}
						}
					}
				}
				&:not(.selected) {
					&:hover {
						&:before{
							color: $linkColor--hover;
							transition: color 0.3s ease;
						}
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.search {
			float: right;
			padding: 0 0 0 1em;
			width: 250px;
			@include media(lg) {
				margin: 0;
				width: 100%;
			}
		}

		.menu-wrapper {
			float: left;
			width: calc(100% - 250px);
			@include media(lg) {
				width: 100%;
			}
		}
	}
}

