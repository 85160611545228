.mobile,
.tablet {
  &.menu-visible{
    nav {
      left: 0;
      transition: left 0.2s ease-out;
    }
  }
  nav {
    background: $white;
    position: fixed;
    top: 0;
    left: -100%;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 260px;
    z-index: 1;
    text-align: left;
    transition: transform 0s ease-out, visibility 0s;
    transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
    border: none;
    border-right: 5px solid $linkColor;

    &.show-menu {
      display: block !important;
      height: calc(100vh - 3em);
      transform: translate3d(0, 0, 0) !important;
      transition: transform 0.4s ease-out;
      visibility: visible !important;
    }

    .menu_level_1 {
      display: block;
      padding: 0;

      > li {
        display: block;
        width: auto;
        .arrow {
          right: 6px;
          top: 2px;
          &:before{
            transform: rotate(180deg);
            margin: -12px 0 0 -10px;
            color: $textColor;
          }
        }
        &.selected {
          background: $linkColor;
          & > .mob-icon {
            color: #222 !important;
          }

          &.subcat {
           .menu_level_2 {
            display: block;
          }
        }
      }

      &.subcat {
        & > a {
        }
      }

      &.open {
        .menu_level_2 {

        }

        > .arrow {
         &:before{
           transform: translate3d(0,0,0);
           transition: transform 0.3s ease;
         }


         &.rotate {

          &:before {
            transform: rotate(0deg);
            transition: transform 0.3s ease;
          }

        }
      }
    }

    & > a {
      display: block;
      color: $textColor;
      padding: 0.3em 1em;
      text-align: left;
    }
    &.selected {
     > .arrow{
      &:before{
        color: $white;
      }
    }
    & > a {
      color: $white;
    }
  }

  &:not(.selected) {
    &:hover{
      .arrow{
        &:before{
              // color: $linkColor--hover;
            }
          }
        }
        & > a {
          &:hover {
            // color: $linkColor--hover;
          }
        }
      }
    }

    .mob-icon {
      display: inline-block;
      // top: 0.8em;
      left: 0.5em;
    }
  }

  .menu_level_2 {
    background: rgba(239, 239, 239,1);
    display: none;
    position: static;
    overflow: hidden;
    opacity: 1;
    transition: all 0s;
    visibility: visible;
    will-change: transform;
    z-index: 5;
    margin: 0;
    border-radius: 0;

    & > li {
      display: block;
      padding: 0;
      position: relative;
      .arrow{
        top: 5px;
        // display: none;
        &:before{
          // transform: rotate(180deg);
        }
      }
      &.open{
        > .arrow {
         &:before{
           transform: translate3d(0,0,0);
           transition: transform 0.3s ease;
         }
         &.rotate {
          &:before {
            transform: rotate(0deg);
            transition: transform 0.3s ease;
          }
        }
      }
    }

    & > a {
      padding: 0.5em 1em 0.6em 2em;
      color: $textColor;
      white-space: normal;
    }
    &.selected {
      & > a {
        color: $linkColor;
      }
      &.subcat {
        .menu_level_3 {
          display: block;
        }
      }
    }

    &:not(.selected) {
      & > a {
        &:hover {
        }
      }
    }
    ul{
      position: relative;
      top: 0;
      left: 0;
      opacity: 1;
      visibility: visible;
      transform: none;
      box-shadow: none;
      padding: 0;
    }
  }


  .mob-icon {
    display: none;
  }
}

.menu_level_3,
.menu_level_4,
.menu_level_5 {
  display: none;
  background: #e8e8e8;
  position: relative;
  opacity: 1;
  visibility: visible;
  border-left: none;
    // padding: 0 0.5em 0 1em;
    left: inherit;
    box-shadow: none;
    transform: none;
    & > li {
      position: relative;
      display: block;
      padding: 0;
      .arrow{
        top: -2px;
      }
      &.open{
        > .arrow {
         &:before{
           transform: translate3d(0,0,0);
           transition: transform 0.3s ease;
         }
         &.rotate {
          &:before {
            transform: rotate(0deg);
            transition: transform 0.3s ease;
          }
        }
      }
    }
    & > a {
      white-space: normal;
      padding: 0.3em 2em 0.3em 2em;
      color: $textColor;
      font-size: 13px;
    }
    &.selected {
      & > a {
        color: $linkColor;
      }
    }

    &:not(.selected) {
      & > a {
        &:hover {
        }
      }
    }
  }
}
.menu_level_4 {
  background: #fff;
}
.menu_level_5 {
  background: #e8e8e8;
}
}
}



.mobile,
.tablet {

  .nav-left {
    padding: 0;
  }


  nav {
    .mail{
      .mail-wrapper{
        position: relative;
        text-align: center;
        padding: 0.5em 0;
        .icon {
          left: 10px;
          @include fontawesome('\f0e0') {
            color: $textColor;
          }
        }
        .mail-link{
          color: $textColor;
          &:hover{
            color: $linkColor--hover;
          }
        }
      }
    }
    .call-order-wrapper {
      text-align: center;
      margin: 0 0 1em;
    }
  }
}


/* Левое меню */


.mobile,
.tablet {
  .nav-left {

    .menu_level_1 {
      display: block;

      & > li {
        position: relative;

        .mob-icon {
          margin-top: -7px;
          top: 19px;
          left: 0.5em;
          z-index: 1;
        }

        & > a {
          padding: 5px 1em;
          border-radius: $borderRadius;
        }
        &.selected{
          .mob-icon{
            color: $white !important;
          }
        }
      }
    }
  }
}

